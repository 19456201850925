import { useState, forwardRef, useEffect } from "react";
import styles from "./form.module.css";
import buttons from "./buttons.module.css";
import Spinner from "Blocks/Spinner/Spinner";

const InputField = ({ id, label, value, setValue, disabled, min = "0" }) => (
    <div className={styles.field}>
        <label htmlFor={id}>{label}</label>
        <input type="number" id={id} value={value}
            onChange={e => setValue(e.target.value)} min={min} required disabled={disabled} />
    </div>
);

const CardField = forwardRef((props, ref) => {
    const { id, label, onChange, disabled = false, value } = props;
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (value) setInputValue(formatCardNumber(value));
    }, [value]);

    const handleInputChange = (event) => {
        let value = event.target.value;

        // Убираем все, кроме цифр
        let rawValue = value.replace(/[^0-9]/g, '');

        // Ограничение на 12 цифр (без пробелов)
        rawValue = rawValue.slice(0, 12);

        // Форматируем для отображения (пробелы каждые 4 цифры)
        const formattedValue = rawValue.replace(/(.{4})/g, '$1 ').trim();

        // Обновляем состояние
        setInputValue(formattedValue);

        // Передаем значение без пробелов в onChange
        onChange(rawValue);
    };

    const formatCardNumber = (value) => {
        // Форматирование строки с добавлением пробелов каждые 4 цифры
        return value.replace(/(.{4})/g, '$1 ').trim();
    };

    return (
        <div className={styles.field}>
            <label htmlFor={id}>{label}</label>
            <input
                type="text"
                name="card"
                id={id}
                inputMode="numeric"
                ref={ref}
                className={styles.field}
                value={inputValue}
                placeholder="0000 0000 0000"
                onChange={handleInputChange}
                maxLength={14}  // 12 цифр + 2 пробела
                autoComplete="cc-number"
                disabled={disabled}
                required
            />
        </div>
    );
});



// Test account 7020672426 
// Test order 837332907
export default function Form({ onSubmit = () => {}, disabled = false }) {
    const [formData, setFormData] = useState({
        account: "",
        amount: ""
    });

    const handleChange = (field) => (value) => {
        setFormData(prev => ({ ...prev, [field]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        onSubmit(formData);
    }

    return (
        <div>
            <form className={styles.Form} onSubmit={handleSubmit} disabled={disabled}>

                <CardField id="account" label="Card number" value={formData.account} 
                    onChange={handleChange('account')} disabled={disabled} /> 

                <InputField id="amount" label="Top-up amount in rubles" value={formData.amount}
                    setValue={handleChange('amount')} min="1" disabled={disabled} />

                <div className={styles.footer}>
                    <button className={buttons.Button} type="submit" disabled={disabled}>
                        {disabled && <Spinner width="20" height="20" stroke="5" color="#fff" />}
                        {disabled ? "Checking the card number.." : formData.amount ? `Top up with ${formData.amount} rubles` : "Continue"}
                    </button>
                </div>
            </form>
        </div>
    );
}
