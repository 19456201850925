import styles from './button.module.css';
import rub from "./rub.svg"; 
import kzt from "./kzt.svg"; 
import usd from "./usd.svg"; 
import eur from "./eur.svg"; 

const icons = {
    "KZT": kzt,
    "RUB": rub,
    "USD": usd,
    "EUR": eur
}

const texts = {
    "KZT": "by bank card",
    "USD": "by bank card",
    "EUR": "by bank card",
    "RUB": "with QR code of any Russian bank"
}

export default function PaymentButton({ currency = "USD", amount = "0.00", description = "...", type = "", onClick, children }) {
    return (
        <button className={styles.PaymentButton} type={type} onClick={onClick}>
            <div className={styles.icon}>{ children }</div>
            <div className={styles.text}>
                <div className={styles.amount}><img src={icons[currency]}/> {amount}</div>
                <div className={styles.description}>{texts[currency]}</div>
            </div>
        </button>
    );
};